export default class CarouselWithControls {
  constructor(el, settings) {
    let isScriptLoaded = false;
    const loadScript = async () => {
      if (isScriptLoaded) return;

      Promise.all([
        import('flickity-fade'),
        import('flickity/dist/flickity.css'),
      ]).then(([module]) => {
        const Flickity = module.default;
        this.carouselBlock = el;
        this.settings = settings || {};
        this.carouselSlides = this.carouselBlock.querySelector('.js-carousel__slides');
        this.carouselCount = this.carouselBlock.querySelector('.js-carousel__count');
        this.carouselCurrent = this.carouselBlock.querySelector('.js-carousel__current');
        this.carouselControls = this.carouselBlock.querySelector('.js-carousel__controls');
        this.carouselPrevBtn = this.carouselBlock.querySelector('.js-carousel__prev');
        this.carouselNextBtn = this.carouselBlock.querySelector('.js-carousel__next');
        if (this.carouselSlides) {
          this.initCarousel(Flickity);
        }
      });

      isScriptLoaded = true;
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadScript();
      });
    });
    observer.observe(el);
  }

  initCarousel(Flickity) {
    this.carousel = new Flickity(this.carouselSlides, Object.assign({
      adaptiveHeight: false,
      contain: true,
      prevNextButtons: false,
      pageDots: true,
      groupCells: true,
      freeScroll: false,
    }, this.settings));

    this.carouselCount.textContent = this.carousel.slides.length;
    this.carouselPrevBtn.disabled = true;
    this.registerEvents();
  }

  registerEvents() {
    window.addEventListener('load', () => {
      window.dispatchEvent(new Event('resize'));
    });

    this.carouselPrevBtn.addEventListener('click', () => {
      this.carousel.previous();
    });
    this.carouselNextBtn.addEventListener('click', () => {
      this.carousel.next();
    });
    this.carousel.on('change', this.updatePagerUI.bind(this));
  }

  updatePagerUI(e) {
    this.carouselCurrent.textContent = e + 1;
    this.carouselCount.textContent = this.carousel.slides.length;
    this.carouselPrevBtn.disabled = e === 0;
    this.carouselNextBtn.disabled = e === this.carousel.slides.length - 1;
    this.carousel.slides.forEach((slide) => {
      const { element } = slide.cells[0];
      const video = element.querySelector('video');
      if (video) {
        video.pause();
        const btnEl = video.nextElementSibling;
        btnEl.querySelector('.js-video-pause').setAttribute('hidden', true);
        btnEl.querySelector('.js-video-play').removeAttribute('hidden');
      }
    });
  }
}
