import CarouselWithControls from '@classes/CarouselWithControls';

export default function init() {
  const carousels = document.querySelectorAll('.js-full-width-image-carousel');
  carousels.forEach((el) => {
    // eslint-disable-next-line no-new
    new CarouselWithControls(el, {
      groupCells: false,
      draggable: false,
      autoPlay: 6000,
      fade: true,
    });
  });
}
