let instagramScriptAdded = false;
let instagramScriptLoaded = false;
const instagramScript = document.createElement('script');

let twitterScriptAdded = false;
let twitterScriptLoaded = false;
const twitterScript = document.createElement('script');

let facebookScriptAdded = false;
let facebookScriptLoaded = false;
let facebookSdkInit = false;
const facebookScript = document.createElement('script');

let codepenScriptAdded = false;
let codepenScriptLoaded = false;
const codepenScript = document.createElement('script');

function addScript(options) {
  const { scriptTag } = options;
  scriptTag.setAttribute('src', options.src);
  scriptTag.async = options.async;
  document.body.appendChild(scriptTag);
}

export function instagram() {
  return new Promise((resolve) => {
    if (instagramScriptAdded && instagramScriptLoaded) {
      resolve(true);
    } else {
      if (!instagramScriptAdded) {
        addScript({
          scriptTag: instagramScript,
          src: 'https://www.instagram.com/embed.js',
          async: true,
        });
        instagramScriptAdded = true;
      }
      instagramScript.addEventListener('load', () => {
        instagramScriptLoaded = true;
        resolve(true);
      });
    }
  });
}

export function twitter() {
  return new Promise((resolve) => {
    if (twitterScriptAdded && twitterScriptLoaded) {
      resolve(true);
    } else {
      if (!twitterScriptAdded) {
        addScript({
          scriptTag: twitterScript,
          src: 'https://platform.twitter.com/widgets.js',
          async: true,
        });
        twitterScriptAdded = true;
      }
      twitterScript.addEventListener('load', () => {
        twitterScriptLoaded = true;
        resolve(true);
      });
    }
  });
}

export function facebook() {
  return new Promise((resolve) => {
    if (facebookScriptAdded && facebookScriptLoaded) {
      resolve(true);
    } else {
      if (!facebookScriptAdded) {
        addScript({
          scriptTag: facebookScript,
          src: 'https://connect.facebook.net/en_US/sdk.js',
          async: true,
        });
        facebookScriptAdded = true;
      }
      facebookScript.addEventListener('load', () => {
        if (!facebookSdkInit) {
          facebookSdkInit = true;
          window.FB.init({
            appId: '1769038996493498',
            autoLogApp: true,
            xfbml: true,
            version: 'v2.12',
          });
        }
        facebookScriptLoaded = true;
        resolve(true);
      });
    }
  });
}

export function codepen() {
  return new Promise((resolve) => {
    if (codepenScriptAdded && codepenScriptLoaded) {
      resolve(true);
    } else {
      if (!codepenScriptAdded) {
        addScript({
          scriptTag: twitterScript,
          src: 'https://static.codepen.io/assets/embed/ei.js',
          async: true,
        });
        codepenScriptAdded = true;
      }
      codepenScript.addEventListener('load', () => {
        codepenScriptLoaded = true;
        resolve(true);
      });
    }
  });
}
