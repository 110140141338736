import CarouselWithControls from '@classes/CarouselWithControls';

export default function init() {
  document.querySelectorAll('.js-project-carousel').forEach((el) => {
    // eslint-disable-next-line no-new
    new CarouselWithControls(el, {
      groupCells: true,
      cellAlign: 'left',
      draggable: false,
      adaptiveHeight: window.innerWidth < 760,
    });
  });
}
