function handleEvent(e, triggers, gridThumbImages) {
  const i = Array.from(triggers).indexOf(e.target);
  const activeImage = gridThumbImages[i];
  if (activeImage) {
    gridThumbImages.forEach(image => image.classList.remove('is-active'));
    activeImage.classList.add('is-active');
  }
}

function initGridThumbs(el) {
  const gridThumbImages = el.querySelectorAll('img');
  if (gridThumbImages.length) {
    const triggerWidth = Math.round(100 / gridThumbImages.length);
    const triggers = el.querySelectorAll('.js-grid-thumb-trigger');
    triggers.forEach(trigger => {
      trigger.style.width = `${triggerWidth}%`;
      trigger.addEventListener('mouseover', (e) => handleEvent(e, triggers, gridThumbImages));
      trigger.addEventListener('touchstart', (e) => handleEvent(e, triggers, gridThumbImages));
    });
  }
}

export default function init() {
  document.querySelectorAll('.js-grid-thumb').forEach((el) => {
    initGridThumbs(el);
  });
}
