// utils
import '@styles/utilities/animations.scss';
import '@styles/utilities/shading.scss';
import '@styles/utilities/spacing.scss';
import '@styles/utilities/typography.scss';
import '@styles/utilities/alignment.scss';

// reset
import '@styles/reset/layout.scss';
import '@styles/reset/typography.scss';
import '@styles/reset/add-this.scss';

// elements
import '@styles/elements/e-button.scss';
import '@styles/elements/e-eyebrow-heading.scss';
import '@styles/elements/e-timeline-eyebrow.scss';
import '@styles/elements/e-heading.scss';
import '@styles/elements/e-link.scss';
import '@styles/elements/e-social-list.scss';
import '@styles/elements/e-text.scss';
import '@styles/elements/e-video-btn.scss';
import '@styles/elements/e-author.scss';
import '@styles/elements/e-parsley-errors-list.scss';
import '@styles/elements/e-related-content.scss';
import '@styles/elements/e-overlay-image.scss';
import '@styles/elements/e-chevron-arrow.scss';
import '@styles/elements/e-overlay-line.scss';
import '@styles/elements/e-overlay-thin-line.scss';
import '@styles/elements/e-shuffle.scss';
import '@styles/elements/e-carousel-controls.scss';
import '@styles/elements/e-parsley-errors-message.scss';
import '@styles/elements/e-stat.scss';
import '@styles/elements/e-full-width-image.scss';
import '@styles/elements/e-tiny-head.scss';
import '@styles/elements/e-inline-list.scss';
import '@styles/elements/e-shard-image.scss';

// blocks
import '@styles/blocks/b-formie.scss';
import '@styles/blocks/b-section-header.scss';
import '@styles/blocks/b-site-footer.scss';
import '@styles/blocks/b-site-header.scss';
import '@styles/blocks/b-modal-notification.scss';
import '@styles/blocks/b-toast.scss';
import '@styles/blocks/b-anniversary-story.scss';
import '@styles/blocks/b-home-banner.scss';
import '@styles/blocks/b-aside-nav.scss';
import '@styles/blocks/b-banner.scss';
import '@styles/blocks/b-blog-card.scss';
import '@styles/blocks/b-blog-listing.scss';
import '@styles/blocks/b-featured-blog.scss';
import '@styles/blocks/b-newsletter.scss';
import '@styles/blocks/b-section-end-cta.scss';
import '@styles/blocks/b-team-member.scss';
import '@styles/blocks/b-wysiwyg.scss';
import '@styles/blocks/b-pagination.scss';
import '@styles/blocks/b-spinner.scss';
import '@styles/blocks/b-person-card.scss';
import '@styles/blocks/b-scrapbook-card.scss';
import '@styles/blocks/b-carousel-card.scss';
import '@styles/blocks/b-social-cta.scss';
import '@styles/blocks/b-values.scss';
import '@styles/blocks/b-breakout-copy.scss';
import '@styles/blocks/b-video.scss';
import '@styles/blocks/b-contact-details.scss';
import '@styles/blocks/b-contact-form.scss';
import '@styles/blocks/b-contact-carousel.scss';
import '@styles/blocks/b-accreditations.scss';
import '@styles/blocks/b-condensed-testimonial.scss';
import '@styles/blocks/b-landing-banner.scss';
import '@styles/blocks/b-project.scss';
import '@styles/blocks/b-what-we-do.scss';
import '@styles/blocks/b-project-listing.scss';
import '@styles/blocks/b-blogswheader.scss';
import '@styles/blocks/b-landing-content.scss';
import '@styles/blocks/b-landing-testimonial.scss';
import '@styles/blocks/b-service-banner.scss';
import '@styles/blocks/b-service-summary.scss';
import '@styles/blocks/b-text-image-block.scss';
import '@styles/blocks/b-info-panel.scss';
import '@styles/blocks/b-project-banner.scss';
import '@styles/blocks/b-device.scss';
import '@styles/blocks/b-media-two-up.scss';
import '@styles/blocks/b-native-video.scss';
import '@styles/blocks/b-project-card.scss';
import '@styles/blocks/b-project-filter.scss';
import '@styles/blocks/b-stats.scss';
import '@styles/blocks/b-testimonial.scss';
import '@styles/blocks/b-job.scss';
import '@styles/blocks/b-tiny-heads.scss';
import '@styles/blocks/b-collage-card.scss';
import '@styles/blocks/b-method.scss';
import '@styles/blocks/b-related-services.scss';
import '@styles/blocks/b-service-listing.scss';


// layouts
import '@styles/layouts/l-content-container.scss';
import '@styles/layouts/l-card-project-grid.scss';
import '@styles/layouts/l-scrapbook-grid.scss';
import '@styles/layouts/l-2-col-aside.scss';
import '@styles/layouts/l-card-grid.scss';
import '@styles/layouts/l-featured-blogs.scss';
import '@styles/layouts/l-scrapbook-carousel.scss';
import '@styles/layouts/l-team-grid.scss';
import '@styles/layouts/l-featured-team.scss';
import '@styles/layouts/l-project-carousel.scss';
import '@styles/layouts/l-service-summary.scss';
import '@styles/layouts/l-devices.scss';
import '@styles/layouts/l-fifty.scss';
import '@styles/layouts/l-project-intro.scss';

// pages
import '@styles/pages/p-preload.scss';
import '@styles/pages/p-team.scss';

import buttons from '@modules/buttons';
import stickyNav from '@modules/stickyNav';
import headerHeight from '@modules/headerHeight';
import modalNotification from '@modules/modalNotification';
import toast from '@modules/toast';
import socialIcons from '@modules/socialIcons';
import videoThumbnails from '@modules/video-thumbnails';
import formieCached from '@modules/formieCached';
import formie from '@modules/formie';
import plausibleSubmission from '@modules/plausibleSubmission';
import linkedInSubmission from '@modules/linkedInSubmission';
import gtmSubmission from '@modules/gtmSubmission';
import chevronCarousel from '@modules/chevronCarousel';
import scrapbookCarousel from '@modules/scrapbookCarousel';
import contactCarousel from '@modules/contactCarousel';
import videoControls from '@modules/videoControls';
import projectGrid from '@modules/projectGrid';
import projectCarousel from '@modules/projectCarousel';
import textImageCarousel from '@modules/textImageCarousel';
import fullWidthImageCarousel from '@modules/fullWidthImageCarousel';
import projectCard from '@modules/projectCard';
import projectFilter from '@modules/projectFilter';
import projectHeroCarousel from '@modules/projectHeroCarousel';
import glightbox from '@modules/glightbox';
import main from '@commons/main.js'

document.body.classList.remove('p-preload');
buttons();
stickyNav();
headerHeight();
toast();
modalNotification();
socialIcons();
videoThumbnails();
formieCached();
formie();
plausibleSubmission();
linkedInSubmission();
gtmSubmission();
chevronCarousel();
scrapbookCarousel();
contactCarousel();
videoControls();
projectGrid();
projectCarousel();
textImageCarousel();
fullWidthImageCarousel();
main();
projectCard();
projectFilter();
projectHeroCarousel();
glightbox();
async function async() {
  if (document.querySelector('#team-grid')) {
    // Perform dynamic import
    const module = await import('@views/team');
    // Use the imported module
    module.default();
  }
}
async();

// Run the headerHeight function a 2nd time on page load so we can get the correct height of the header after it's more likely styles have loaded in.
document.addEventListener('DOMContentLoaded', () => {
  headerHeight();
});

window.addEventListener('load', () => {
  // Run the headerHeight function a 3rd time on page load so we can get the correct height of the header after it's more likely styles have loaded in.
  headerHeight();
});


// Run the headerHeight function after any transitions have occured.
window.onresize = () => {
  setTimeout(headerHeight, 500);
};
