import Headroom from 'headroom.js';

export default function init() {
  const myElement = document.querySelector('.js-site-header');
  // construct an instance of Headroom, passing the element
  const headroom = new Headroom(myElement, {
    offset: 110,
    classes: {
      initial: 'b-site-header--scroll',
      // when scrolling up
      pinned: 'b-site-header--pinned',
      // when scrolling down
      unpinned: 'b-site-header--unpinned',
      // when above offset
      top: 'b-site-header--top',
      // when below offset
      notTop: 'b-site-header--not-top',
      // when at bottom of scoll area
      bottom: 'b-site-header--bottom',
      // when not at bottom of scroll area
      notBottom: 'b-site-header--not-bottom',
    },
  });
  // initialise
  headroom.init();
}
