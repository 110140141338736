import CarouselWithControls from '@classes/CarouselWithControls';

export default function init() {
  document.querySelectorAll('.js-text-image-carousel').forEach((el) => {
    // eslint-disable-next-line no-new
    new CarouselWithControls(el, {
      groupCells: false,
      draggable: false,
      pageDots: true,
      autoPlay: 6000,
      fade: true,
    });
  });
}
