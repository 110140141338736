
/*
 Removes the vimeo thumbnail if marketing cookies when accepted
*/

export default function init() {
  window.addEventListener('CookiebotOnAccept', () => {
    if (Cookiebot.consent.marketing) {
      document.querySelectorAll('.js-video-thumbnail').forEach(((element) => {
        element.parentNode.removeChild(element);
      }));
    }
  });
}
