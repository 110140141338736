export default function init() {
  let isScriptLoaded = false;
  const loadScript = async () => {
    if (isScriptLoaded) return;

    import('glightbox/dist/css/glightbox.css');
    import('glightbox')
      .then((module) => {
        const GLightbox = module.default;
        GLightbox({
          selector: '.js-lightbox',
          plyr: {
            config: {
              vimeo: {
                title: false,
              },
            },
          },
        });
      })
      .catch(err => console.error(`Could not load module: ${err}`));

    isScriptLoaded = true;
  };

  const elements = [...document.querySelectorAll('.js-lightbox')];
  elements.forEach((element) => {
    // Load script whenever a given form is visible
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!entry.isIntersecting) return;

        await loadScript();
      });
    });
    observer.observe(element);
  });
}
