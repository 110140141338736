import CarouselWithControls from '@classes/CarouselWithControls';

export default function init() {

  const carousels = document.querySelectorAll('.js-scrapbook-carousel');
  carousels.forEach((el) => {
    // eslint-disable-next-line no-new
    new CarouselWithControls(el);
  });
}
