/*
* As the header is taken out of the document flow (via position: fixed)
* we need to preserve its height in the document
* This function ensures that the height of the header matches its content
* It should be ran on page load along with any window resize event.
*/
export default function headerHeight() {
  const header = document.querySelector('.b-site-header');
  const headerContent = header.querySelector('.b-site-header__content');
  header.style.height = `${headerContent.clientHeight}px`;
}
